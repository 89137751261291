import React, { useEffect, useState } from "react";
import { useLanguage } from "../hooks/useLang";

import { Link, useHistory } from "react-router-dom";
import PageHead from "../pageBlocks/pageHead/pageHead";
import CaptchaNum from "../pageBlocks/captcha";
import axios from "axios";
import { verification } from "../utils/verification";
import Loader from "../components/loader";

const URL = "/auth/public-api/site/request/";

const QuestionPage = () => {
    const { content, interfaceNames } = useLanguage();
    // имена полей и текст контента в зависимости от установленного языка
    const areasNames = interfaceNames.question.page;
    const pageName = content.question.pageName;
    const subMenuList = content.question.subMenuList;

    const history = useHistory();
    const [verify, setVerify] = useState({
        name: false,
        ltd: false,
        phonenum: false,
        email: false
    });
    const [readyToSend, setReadyness] = useState(false);
    const [correctCaptcha, setCaptcha] = useState(false);
    const [sendStatus, setSendStatus] = useState("DEFAULT");
    const [askForm, setAskForm] = useState({
        name: "",
        ltd: "",
        phonenum: "",
        email: "",
        comment: "",
        agreement: false
    });
    useEffect(() => {
        console.log("sendStatus in question page", sendStatus);
    }, [sendStatus]);
    function sendQuestion(form) {
        setSendStatus("INPROGRESS");
        axios
            .post(URL, {
                name: form.name,
                ltd: form.ltd,
                phonenum: form.phonenum,
                email: form.email,
                comment: form.comment,
                agreement: form.agreement,

                is_formal: true
            })
            .then((resp) =>
                resp.status === 200
                    ? setSendStatus("OK")
                    : setSendStatus("INPROGRESS")
            )
            .catch((err) => setSendStatus("ERROR"));
    }

    const afterSendAction = (route) => {
        setSendStatus("DEFAULT");
        history.push(route);
    };

    useEffect(() => {
        setVerify(verification(askForm));
        const status = verification(askForm);
        if (
            status.name &&
            status.ltd &&
            status.email &&
            status.phonenum &&
            askForm.comment &&
            askForm.agreement
        ) {
            setReadyness(true);
        } else {
            setReadyness(false);
        }
    }, [askForm]);
    const handleSubmit = async (event) => {
        event.preventDefault();
        sendQuestion(askForm);
    };
    const handleChange = ({ target }) => {
        if (target.name === "agreement") {
            setAskForm((prevState) => ({
                ...prevState,
                [target.name]: target.checked
            }));
        } else {
            setAskForm((prevState) => ({
                ...prevState,
                [target.name]: target.value
            }));
        }
    };
    return (
        <div className="mainPage">
            <PageHead
                pageName={pageName}
                subMenuList={subMenuList}
            />
            <div className="questionPage bborder">
                {sendStatus === "DEFAULT" ? (
                    <form
                        onSubmit={handleSubmit}
                        className="questionPage-form pt-60"
                        id="askQuestion"
                    >
                        <div
                            className="questionPage-form-goback bborder fs-24 mb-60 cp"
                            onClick={() => history.goBack()}
                        >
                            {areasNames.back}
                            <div className="questionPage-form-goback-arrow imgBack"></div>
                        </div>
                        <div className="questionPage-input-back bborder ">
                            <span className="questionPage-num fs-16 ">1.</span>
                            <input
                                onChange={handleChange}
                                className="questionPage-input fs-16  "
                                placeholder={`${areasNames.name}*`}
                                type="text"
                                name="name"
                                maxLength="100"
                                // required
                                style={verify.name ? {} : { color: "red" }}
                            />
                        </div>
                        <div className="questionPage-input-back bborder">
                            <span className="questionPage-num fs-16 ">2.</span>
                            <input
                                onChange={handleChange}
                                className="questionPage-input fs-16 "
                                placeholder={`${areasNames.llc}*`}
                                type="text"
                                name="ltd"
                                maxLength="100"
                                style={verify.ltd ? {} : { color: "red" }}
                            />
                        </div>
                        <div className="questionPage-input-back bborder">
                            <span className="questionPage-num fs-16 ">3.</span>
                            <input
                                onChange={handleChange}
                                className="questionPage-input fs-16 "
                                id="questionPage-input-phonenum"
                                placeholder={`${areasNames.phonenum}*`}
                                type="tel"
                                name="phonenum"
                                maxLength="25"
                                style={verify.phonenum ? {} : { color: "red" }}
                            />
                        </div>
                        <div className="questionPage-input-back bborder">
                            <span className="questionPage-num fs-16 ">4.</span>
                            <input
                                onChange={handleChange}
                                className="questionPage-input fs-16 "
                                id="questionPage-input-email"
                                placeholder="E-mail*"
                                type="email"
                                name="email"
                                style={verify.email ? {} : { color: "red" }}
                            />
                        </div>
                        <div className="questionPage-input-back bborder ">
                            <span className="questionPage-num fs-16 ">5.</span>
                            <textarea
                                onChange={handleChange}
                                rows="4"
                                className="questionPage-input fs-16 "
                                placeholder={`${areasNames.textarea}*`}
                                type="textarea"
                                name="comment"
                                maxLength="1000"
                                // required
                            />
                        </div>
                        {<p>{`* - ${areasNames.requiered}`}</p>}
                        <div>
                            <input
                                onChange={handleChange}
                                className="questionPage-input-checkbox fs-16  "
                                type="checkbox"
                                name="agreement"
                                id="agreement"
                            />
                            <label
                                htmlFor="agreement"
                                className="questionPage-input-checkbox-label mt-30"
                            >
                                <div></div>
                            </label>
                            <span style={{ paddingLeft: 15 + "px" }}>
                                {areasNames.agreement.beforeLink}{" "}
                                <Link
                                    to="/personalDataAgreementPage"
                                    style={{ textDecoration: "underline" }}
                                >
                                    {areasNames.agreement.link}
                                </Link>{" "}
                                {areasNames.agreement.afterLink}
                            </span>
                        </div>

                        {correctCaptcha ? (
                            <div
                                className="questionPage-textBlock-link bborder fs-32 mt-60 mb-60"
                                style={
                                    readyToSend
                                        ? {}
                                        : {
                                              borderBottom:
                                                  "1px solid lightgray"
                                          }
                                }
                            >
                                <button
                                    form="askQuestion"
                                    type="submit"
                                    className="questionPage-textBlock-link-name cp fs-32"
                                    style={
                                        readyToSend
                                            ? {}
                                            : {
                                                  color: "lightgray"
                                              }
                                    }
                                    disabled={readyToSend ? false : true}
                                >
                                    {areasNames.send}
                                </button>
                                <button
                                    form="askQuestion"
                                    type="submit"
                                    className="questionPage-textBlock-link-arrow imgBack cp"
                                    disabled={readyToSend ? false : true}
                                    style={
                                        readyToSend
                                            ? {}
                                            : {
                                                  color: "lightgray",
                                                  opacity: "0.3"
                                              }
                                    }
                                ></button>
                            </div>
                        ) : (
                            <div className="questionPage-captcha mb-60 pt-40">
                                <CaptchaNum setAnswer={setCaptcha} />
                            </div>
                        )}
                    </form>
                ) : sendStatus === "INPROGRESS" ? (
                    <Loader />
                ) : (
                    <div className="questionPage-successSend">
                        {sendStatus === "OK" ? (
                            <div className="questionPage-successSend-text fs-40 ">
                                {areasNames.success}
                            </div>
                        ) : (
                            <div className="questionPage-successSend-text fs-40 ">
                                {areasNames.error.line1}
                                <br />
                                {areasNames.error.line2}
                                <br />
                                {areasNames.error.line3}
                            </div>
                        )}
                        {sendStatus === "ERROR" && (
                            <div
                                className="questionPage-successSend-notOk bborder fs-32 cp"
                                onClick={() => afterSendAction("/questionPage")}
                            >
                                {areasNames.tryAgain}
                            </div>
                        )}
                        <div
                            className="questionPage-successSend-ok bborder fs-32 cp"
                            onClick={() => afterSendAction("/")}
                        >
                            {areasNames.returnLink}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default QuestionPage;
