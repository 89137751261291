import React, { useEffect, useState } from "react";
import { useLanguage } from "../hooks/useLang";

import { Link, useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import CaptchaNum from "../pageBlocks/captcha";
import PageHead from "../pageBlocks/pageHead/pageHead";
// import content from "../textContent";
import axios from "axios";
import { verification } from "../utils/verification";
import Loader from "../components/loader";

const URL = "/auth/public-api/site/request/";
// const URL = "http://app.sc.avl.team/auth/public-api/site/request/";

const RequestPage = () => {
    const { content, interfaceNames } = useLanguage();
    const blockNames = interfaceNames.request.page;

    const pageName = content.request.pageName;
    const subMenuList = content.request.subMenuList;

    const history = useHistory();
    const [verify, setVerify] = useState({
        name: false,
        ltd: false,
        phonenum: false,

        email: false,
        request: false
    });
    const [readyToSend, setReadyness] = useState(false);
    const [correctCaptcha, setCaptcha] = useState(false);
    const [sendStatus, setSendStatus] = useState("DEFAULT");
    const [askForm, setAskForm] = useState({
        name: "",
        ltd: "",
        phonenum: "",
        email: "",
        center: false,
        licence: false,
        learning: false,
        analitycs: false,
        agreement: false
    });

    function sendRequest(form) {
        setSendStatus("INPROGRESS");
        axios
            .post(URL, {
                name: form.name,
                ltd: form.ltd,
                phonenum: form.phonenum,
                email: form.email,
                center: form.center,
                licence: form.licence,
                learning: form.learning,
                analitycs: form.analitycs,
                agreement: form.agreement,
                is_formal: true
            })
            .then((resp) =>
                resp.status === 200
                    ? setSendStatus("OK")
                    : setSendStatus("INPROGRESS")
            )
            .catch((err) => setSendStatus("ERROR"));
    }

    const afterSendAction = (route) => {
        setSendStatus("DEFAULT");
        history.push(route);
    };

    useEffect(() => {
        setVerify(verification(askForm));
        const status = verification(askForm);
        if (
            status.name &&
            status.ltd &&
            status.email &&
            status.phonenum &&
            status.request &&
            askForm.agreement
        ) {
            setReadyness(true);
        } else {
            setReadyness(false);
        }
    }, [askForm]);

    const handleSubmit = (event) => {
        event.preventDefault();
        sendRequest(askForm);
    };
    const handleChange = ({ target }) => {
        if (
            target.name === "agreement" ||
            target.name === "center" ||
            target.name === "licence" ||
            target.name === "learning" ||
            target.name === "analitycs"
        ) {
            setAskForm((prevState) => ({
                ...prevState,
                [target.name]: target.checked
            }));
        } else {
            setAskForm((prevState) => ({
                ...prevState,
                [target.name]: target.value
            }));
        }
    };
    return (
        <div className="mainPage">
            <PageHead
                pageName={pageName}
                subMenuList={subMenuList}
            />
            <div className="requestPage-back bborder pb-60 pt-60">
                {sendStatus === "DEFAULT" ? (
                    <>
                        <form
                            onSubmit={handleSubmit}
                            className="requestPage-form "
                            id="sendRequest"
                        >
                            <div
                                className="requestPage-form-goback bborder fs-24 mb-60 cp"
                                onClick={() => history.goBack()}
                            >
                                {blockNames.back}
                                <div className="requestPage-form-goback-arrow imgBack"></div>
                            </div>
                            <div className="sendRequest-input-back bborder ">
                                <span className="sendRequest-num fs-16 ">
                                    1.
                                </span>
                                <input
                                    onChange={handleChange}
                                    className="sendRequest-input fs-16  "
                                    placeholder={`${blockNames.name}*`}
                                    type="text"
                                    name="name"
                                    maxLength="100"
                                    style={verify.name ? {} : { color: "red" }}
                                />
                            </div>
                            <div className="sendRequest-input-back bborder">
                                <span className="sendRequest-num fs-16 ">
                                    2.
                                </span>
                                <input
                                    onChange={handleChange}
                                    className="sendRequest-input fs-16 "
                                    placeholder={`${blockNames.llc}*`}
                                    type="text"
                                    name="ltd"
                                    maxLength="100"
                                    style={verify.ltd ? {} : { color: "red" }}
                                />
                            </div>
                            <div className="sendRequest-input-back bborder">
                                <span className="sendRequest-num fs-16 ">
                                    3.
                                </span>
                                <input
                                    onChange={handleChange}
                                    className="sendRequest-input fs-16 "
                                    placeholder={`${blockNames.phonenum}*`}
                                    type="tel"
                                    name="phonenum"
                                    maxLength="25"
                                    style={
                                        verify.phonenum ? {} : { color: "red" }
                                    }
                                />
                            </div>
                            <div className="sendRequest-input-back bborder">
                                <span className="sendRequest-num fs-16 ">
                                    4.
                                </span>
                                <input
                                    onChange={handleChange}
                                    className="sendRequest-input fs-16 "
                                    placeholder="E-mail*"
                                    type="email"
                                    name="email"
                                    style={verify.email ? {} : { color: "red" }}
                                />
                            </div>
                            <div>
                                <input
                                    onChange={handleChange}
                                    className="sendRequest-input-checkbox fs-16  "
                                    type="checkbox"
                                    name="center"
                                    id="center"
                                />
                                <label
                                    htmlFor="center"
                                    className="sendRequest-input-checkbox-label mt-20"
                                >
                                    <div></div>
                                </label>
                                <span
                                    style={{
                                        paddingLeft: 15 + "px",
                                        fontSize: 18 + "px"
                                    }}
                                >
                                    {blockNames.checkboxes.center}
                                </span>
                            </div>
                            <div className=" pb-25">
                                <input
                                    onChange={handleChange}
                                    className="sendRequest-input-checkbox fs-16  "
                                    type="checkbox"
                                    name="licence"
                                    id="licence"
                                />
                                <label
                                    htmlFor="licence"
                                    className="sendRequest-input-checkbox-label mt-20 "
                                >
                                    <div></div>
                                </label>
                                <span
                                    style={{
                                        paddingLeft: 15 + "px",
                                        fontSize: 18 + "px"
                                    }}
                                >
                                    {blockNames.checkboxes.licence}
                                </span>
                            </div>
                            <div className=" pb-25">
                                <input
                                    onChange={handleChange}
                                    className="sendRequest-input-checkbox fs-16  "
                                    type="checkbox"
                                    name="analitycs"
                                    id="analitycs"
                                />
                                <label
                                    htmlFor="analitycs"
                                    className="sendRequest-input-checkbox-label  "
                                >
                                    <div></div>
                                </label>
                                <span
                                    style={{
                                        paddingLeft: 15 + "px",
                                        fontSize: 18 + "px"
                                    }}
                                >
                                    {blockNames.checkboxes.analytics}
                                </span>
                            </div>
                            <div className="bborder  pb-25">
                                <input
                                    onChange={handleChange}
                                    className="sendRequest-input-checkbox fs-16  "
                                    type="checkbox"
                                    name="learning"
                                    id="learning"
                                />
                                <label
                                    htmlFor="learning"
                                    className="sendRequest-input-checkbox-label  "
                                >
                                    <div></div>
                                </label>
                                <span
                                    style={{
                                        paddingLeft: 15 + "px",
                                        fontSize: 18 + "px"
                                    }}
                                >
                                    {blockNames.checkboxes.courses}
                                </span>
                            </div>
                            {<p>{`* - ${blockNames.requiered}`}</p>}
                            <div>
                                <input
                                    onChange={handleChange}
                                    className="sendRequest-input-checkbox fs-16  "
                                    type="checkbox"
                                    name="agreement"
                                    id="agreement"
                                />
                                <label
                                    htmlFor="agreement"
                                    className="sendRequest-input-checkbox-label mt-30"
                                >
                                    <div></div>
                                </label>
                                <span style={{ paddingLeft: 15 + "px" }}>
                                    {blockNames.agreement.beforeLink}{" "}
                                    <Link
                                        to="/personalDataAgreementPage"
                                        style={{ textDecoration: "underline" }}
                                    >
                                        {blockNames.agreement.link}
                                    </Link>{" "}
                                    {blockNames.agreement.afterLink}
                                </span>
                            </div>
                            {correctCaptcha ? (
                                <div
                                    className="sendRequest-textBlock-link bborder fs-32 mt-60"
                                    style={
                                        readyToSend
                                            ? {}
                                            : {
                                                  borderBottom:
                                                      "1px solid lightgray"
                                              }
                                    }
                                >
                                    <button
                                        form="sendRequest"
                                        type="submit"
                                        className="sendRequest-textBlock-link-name  fs-32"
                                        style={
                                            readyToSend
                                                ? { cursor: "pointer" }
                                                : {
                                                      color: "lightgray"
                                                  }
                                        }
                                        disabled={readyToSend ? false : true}
                                    >
                                        {blockNames.send}
                                    </button>
                                    <button
                                        form="sendRequest"
                                        type="submit"
                                        className="sendRequest-textBlock-link-arrow imgBack "
                                        disabled={readyToSend ? false : true}
                                        style={
                                            readyToSend
                                                ? { cursor: "pointer" }
                                                : {
                                                      color: "lightgray",
                                                      opacity: "0.3"
                                                  }
                                        }
                                    ></button>
                                </div>
                            ) : (
                                <div className="sendRequest-captcha pt-40 mb-60">
                                    <CaptchaNum setAnswer={setCaptcha} />
                                </div>
                            )}
                        </form>
                        <div className="requestPage-imageLinks-back">
                            <HashLink
                                to={{
                                    pathname: "/tariffsPage",
                                    hash: "#TariffsPageCenter"
                                }}
                                className="requestPage-imageLinks-link-back mb-60"
                            >
                                <img
                                    src="./img/requestPage/center.jpg"
                                    alt="loading error"
                                    className="requestPage-imageLinks-link-img"
                                />
                                <div className="requestPage-imageLinks-link-name bborder cp">
                                    {blockNames.imageLinks.center}
                                    <div className="requestPage-imageLinks-link-name-arrow imgBack"></div>
                                </div>
                            </HashLink>
                            <HashLink
                                to={{
                                    pathname: "/tariffsPage",
                                    hash: "#TariffsPageLicence"
                                }}
                                className="requestPage-imageLinks-link-back mb-60"
                            >
                                <img
                                    src="./img/requestPage/licence.jpg"
                                    alt="loading error"
                                    className="requestPage-imageLinks-link-img"
                                />
                                <div className="requestPage-imageLinks-link-name bborder cp">
                                    {blockNames.imageLinks.licence}
                                    <div className="requestPage-imageLinks-link-name-arrow imgBack"></div>
                                </div>
                            </HashLink>
                            <Link
                                to="/analyticsPage"
                                className="requestPage-imageLinks-link-back"
                            >
                                <img
                                    src="./img/requestPage/analitycs.jpg"
                                    alt="loading error"
                                    className="requestPage-imageLinks-link-img"
                                />
                                <div className="requestPage-imageLinks-link-name bborder cp">
                                    {blockNames.imageLinks.analytics}
                                    <div className="requestPage-imageLinks-link-name-arrow imgBack"></div>
                                </div>
                            </Link>
                            <Link
                                to="/learningPage"
                                className="requestPage-imageLinks-link-back"
                            >
                                <img
                                    src="./img/requestPage/learning.jpg"
                                    alt="loading error"
                                    className="requestPage-imageLinks-link-img"
                                />
                                <div className="requestPage-imageLinks-link-name bborder cp">
                                    {blockNames.imageLinks.courses}
                                    <div className="requestPage-imageLinks-link-name-arrow imgBack"></div>
                                </div>
                            </Link>
                        </div>
                    </>
                ) : sendStatus === "INPROGRESS" ? (
                    <Loader />
                ) : (
                    <div className="requestPage-successSend">
                        {sendStatus === "OK" ? (
                            <div className="requestPage-successSend-text fs-40 ">
                                {blockNames.success}
                            </div>
                        ) : (
                            <div className="requestPage-successSend-text fs-40 ">
                                {blockNames.error.line1}
                                <br />
                                {blockNames.error.line2}
                                <br />
                                {blockNames.error.line3}
                            </div>
                        )}
                        {sendStatus === "ERROR" && (
                            <div
                                className="requestPage-successSend-notOk bborder fs-32 cp"
                                onClick={() => afterSendAction("/requestPage")}
                            >
                                {blockNames.tryAgain}
                            </div>
                        )}
                        <div
                            className="requestPage-successSend-ok bborder fs-32 cp"
                            onClick={() => afterSendAction()}
                        >
                            {blockNames.returnLink}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default RequestPage;
