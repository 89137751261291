import React, { useEffect, useState } from "react";

import { Link, useHistory } from "react-router-dom";
import CaptchaNumMobile from "../pageBlocksMobile/captchaMobile";
import axios from "axios";
import { verification } from "../../utils/verification";
import Loader from "../../components/loader";
import { useLanguage } from "../../hooks/useLang";
import PageHeadMobile from "../pageBlocksMobile/pageHead/pageHeadMobile";
// import FooterHorizontalBlockTablet from "../../tablet/footerTablet/footerHorizontalBlockTablet";

const URL = "/auth/public-api/site/request/";

const ContactUsPageMobile = () => {
    const { content, interfaceNames } = useLanguage();
    // имена полей и текст контента в зависимости от установленного языка
    const areasNames = interfaceNames.question.page;
    const pageName = content.question.pageName;
    //console.log("Lucky")
    const history = useHistory();
    const [verify, setVerify] = useState({
        name: false,
        ltd: false,
        phonenum: false,
        email: false
    });
    const [readyToSend, setReadyness] = useState(false);
    const [correctCaptcha, setCaptcha] = useState(false);
    const [sendStatus, setSendStatus] = useState("DEFAULT");
    const [askForm, setAskForm] = useState({
        name: "",
        ltd: "",
        phonenum: "",
        email: "",
        comment: "Request from Mobile Version",
        agreement: false
    });
    function sendQuestion(form) {
        setSendStatus("INPROGRESS");
        axios
            .post(URL, {
                name: form.name,
                ltd: form.ltd,
                phonenum: form.phonenum,
                email: form.email,
                comment: form.comment,
                agreement: form.agreement,

                is_formal: true
            })
            .then((resp) =>
                resp.status === 200
                    ? setSendStatus("OK")
                    : setSendStatus("INPROGRESS")
            )
            .catch((err) => setSendStatus("ERROR"));
    }

    const afterSendAction = (route) => {
        setSendStatus("DEFAULT");
        history.push(route);
    };

    useEffect(() => {
        setVerify(verification(askForm));
        const status = verification(askForm);
        if (
            status.name &&
            status.ltd &&
            status.email &&
            status.phonenum &&
            askForm.comment &&
            askForm.agreement
        ) {
            setReadyness(true);
        } else {
            setReadyness(false);
        }
    }, [askForm]);
    const handleSubmit = async (event) => {
        event.preventDefault();
        sendQuestion(askForm);
    };
    const handleChange = ({ target }) => {
        if (target.name === "agreement") {
            setAskForm((prevState) => ({
                ...prevState,
                [target.name]: target.checked
            }));
        } else {
            setAskForm((prevState) => ({
                ...prevState,
                [target.name]: target.value
            }));
        }
    };
    return (
        <div
            className="mainPageMobile mb-40"
            style={{ paddingTop: "0px" }}
        >
            <PageHeadMobile pageName={pageName} />
            <div className="ContactUsPage pt-40 bborder">
                {sendStatus === "DEFAULT" ? (
                    <form
                        onSubmit={handleSubmit}
                        className="ContactUsPage-form"
                        id="askQuestion"
                    >
                        <div
                            className="questionPage-form-goback bborder fs-16 mb-40 cp"
                            onClick={() => history.goBack()}
                        >
                            {areasNames.back}
                            <div className="questionPage-form-goback-arrow imgBack"></div>
                        </div>
                        <div className="questionPage-input-back bborder ">
                            <span className="questionPage-num fs-14 ">1.</span>
                            <input
                                onChange={handleChange}
                                className="questionPage-input fs-14  "
                                placeholder={`${areasNames.name}*`}
                                type="text"
                                name="name"
                                maxLength="100"
                                // required
                                style={verify.name ? {} : { color: "red" }}
                            />
                        </div>
                        <div className="questionPage-input-back bborder">
                            <span className="questionPage-num fs-14 ">2.</span>
                            <input
                                onChange={handleChange}
                                className="questionPage-input fs-14 "
                                placeholder={`${areasNames.llc}*`}
                                type="text"
                                name="ltd"
                                maxLength="100"
                                style={verify.ltd ? {} : { color: "red" }}
                            />
                        </div>
                        <div className="questionPage-input-back bborder">
                            <span className="questionPage-num fs-14 ">3.</span>
                            <input
                                onChange={handleChange}
                                className="questionPage-input fs-14 "
                                id="questionPage-input-phonenum"
                                placeholder={`${areasNames.phonenum}*`}
                                type="tel"
                                name="phonenum"
                                maxLength="25"
                                style={verify.phonenum ? {} : { color: "red" }}
                            />
                        </div>
                        <div className="questionPage-input-back bborder">
                            <span className="questionPage-num fs-14 ">4.</span>
                            <input
                                onChange={handleChange}
                                className="questionPage-input fs-14 "
                                id="questionPage-input-email"
                                placeholder="E-mail*"
                                type="email"
                                name="email"
                                style={verify.email ? {} : { color: "red" }}
                            />
                        </div>

                        {<p>{`* - ${areasNames.requiered}`}</p>}
                        <div>
                            <input
                                onChange={handleChange}
                                className="questionPage-input-checkbox fs-14  "
                                type="checkbox"
                                name="agreement"
                                id="agreement"
                            />
                            <label
                                htmlFor="agreement"
                                className="questionPage-input-checkbox-label mt-20"
                            >
                                <div></div>
                            </label>
                            <span style={{ paddingLeft: 15 + "px" }}>
                                {areasNames.agreement.beforeLink}{" "}
                                <Link
                                    to="/personalDataAgreementPage"
                                    style={{ textDecoration: "underline" }}
                                >
                                    {areasNames.agreement.link}
                                </Link>{" "}
                                <br />
                                <span style={{ paddingLeft: 38 + "px" }}>
                                    {areasNames.agreement.afterLink}
                                </span>
                            </span>
                        </div>

                        {correctCaptcha ? (
                            <div
                                className="questionPage-textBlock-link bborder fs-20 mt-40 mb-40"
                                style={
                                    readyToSend
                                        ? {}
                                        : {
                                              borderBottom:
                                                  "1px solid lightgray"
                                          }
                                }
                            >
                                <button
                                    form="askQuestion"
                                    type="submit"
                                    className="questionPage-textBlock-link-name cp fs-20"
                                    style={
                                        readyToSend
                                            ? {}
                                            : {
                                                  color: "lightgray"
                                              }
                                    }
                                    disabled={readyToSend ? false : true}
                                >
                                    <span
                                        style={{
                                            position: "relative",
                                            top: "5px"
                                        }}
                                    >
                                        {areasNames.send}
                                    </span>
                                </button>
                                <button
                                    form="askQuestion"
                                    type="submit"
                                    className="questionPage-textBlock-link-arrow imgBack cp"
                                    disabled={readyToSend ? false : true}
                                    style={
                                        readyToSend
                                            ? {}
                                            : {
                                                  color: "lightgray",
                                                  opacity: "0.3"
                                              }
                                    }
                                ></button>
                            </div>
                        ) : (
                            <div className="contactUsPage-captcha pb-40 pt-20">
                                <CaptchaNumMobile setAnswer={setCaptcha} />
                            </div>
                        )}
                    </form>
                ) : sendStatus === "INPROGRESS" ? (
                    <Loader />
                ) : (
                    <div className="questionPage-successSend">
                        {sendStatus === "OK" ? (
                            <div className="questionPage-successSend-text fs-20 ">
                                {areasNames.success}
                            </div>
                        ) : (
                            <div className="questionPage-successSend-text fs-24 ">
                                {areasNames.error.line1}
                                <br />
                                {areasNames.error.line2}
                                <br />
                                {areasNames.error.line3}
                            </div>
                        )}
                        {sendStatus === "ERROR" && (
                            <div
                                className="questionPage-successSend-notOk bborder fs-24 cp"
                                onClick={() =>
                                    afterSendAction("/contactUsPage")
                                }
                            >
                                {areasNames.tryAgain}
                            </div>
                        )}
                        <div
                            className="questionPage-successSend-ok bborder fs-24 cp"
                            onClick={() => afterSendAction("/")}
                        >
                            {areasNames.returnLink}
                        </div>
                    </div>
                )}
            </div>

            {/* <FooterHorizontalBlockTablet/> */}
        </div>
    );
};

export default ContactUsPageMobile;
